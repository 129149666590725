import React, { useEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { NavItem } from '../../utils/types';
import Button from '../../components/button';
import './contact.css';

declare const grecaptcha: any;

export interface ContactProps {
  number?: string;
  headerText?: string;
  headerTextMain?: string;
  style?: React.CSSProperties;
  navItems?: Array<NavItem>;
  onNavItemSelected?: (index: number) => void;
}

const Contact: React.FC<ContactProps> = ({
  headerTextMain = 'Contact Us',
  style
}) => {
  const images = useStaticQuery(graphql`
    query {
      bubble: file(relativePath: { eq: "hereforyou.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const captchRendered = useRef(false);
  useEffect(() => {
    function timestamp() {
      const response: any = document.getElementById('g-recaptcha-response');
      if (response === null || response.value.trim() == '') {
        const settings = JSON.parse(
          (document.getElementsByName('captcha_settings')[0] as any).value
        );
        settings['ts'] = JSON.stringify(new Date().getTime());
        (document.getElementsByName(
          'captcha_settings'
        )[0] as any).value = JSON.stringify(settings);
      }
    }
    timestamp();
    const timestampInterval = setInterval(timestamp, 500);
    return () => {
      clearInterval(timestampInterval);
    };
  }, []);
  useEffect(() => {
    let recaptiaRenderInterval;
    const recaptiaRender = () => {
      if (!captchRendered.current && (window as any).recaptchaReady) {
        captchRendered.current = true;
        grecaptcha.render('g-recaptcha');
      } else {
        recaptiaRenderInterval = setTimeout(recaptiaRender, 100);
      }
    };
    recaptiaRender();
    return () => {
      clearTimeout(recaptiaRenderInterval);
    };
  }, [captchRendered]);

  const bubblesImg = images.bubble.childImageSharp.fluid;

  return (
    <div id="Contact" style={style}>
      <div className="Contact-content">
        <div className="Contact-inner-content">
          <div className="Contact-title">
            <div className="Contact-title__content">
              <h1 className="Contact-title__content-main">{headerTextMain}</h1>
            </div>
          </div>
          <div className="Contact-details">
            <p>
              If you need immediate assistance, please call us at (833)
              668-5930.
            </p>
            <p>
              <strong>Visit a FINS</strong>
              <br />
              <br />
              We have locations throughout the Carolinas, offering a superior,
              exterior-only wash that delivers a high quality and memorable
              experience that will keep you coming back. Find the location
              nearest you at{' '}
              <a href="https://finswash.com/#Location">
                FinsWash.com/location.
              </a>
            </p>

            <form
              action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
              method="POST"
            >
              <input
                type="hidden"
                name="captcha_settings"
                value='{"keyname":"FinsWash","fallback":"true","orgId":"00Dj0000001q639","ts":""}'
              />
              <input type="hidden" name="orgid" value="00Dj0000001q639" />
              <input type="hidden" name="retURL" value="http://finswash.com" />

              <label htmlFor="name">Name</label>
              <input
                id="name"
                maxLength={80}
                name="name"
                size={20}
                type="text"
              />
              <br />

              <label htmlFor="email">Email</label>
              <input
                id="email"
                maxLength={80}
                name="email"
                size={20}
                type="text"
              />
              <br />

              <label htmlFor="phone">Phone</label>
              <input
                id="phone"
                maxLength={40}
                name="phone"
                size={20}
                type="text"
              />
              <br />

              <label htmlFor="subject">Subject</label>
              <input
                id="subject"
                maxLength={80}
                name="subject"
                size={20}
                type="text"
              />
              <br />

              <label htmlFor="description">Description</label>
              <textarea name="description"></textarea>
              <br />

              <input
                type="hidden"
                name="recordType"
                id="recordType"
                value="0120a000000vtJz"
              />
              <input type="hidden" id="external" name="external" value="1" />
              <br />
              <input type="hidden" name="debug" value="1" />

              <div
                id="g-recaptcha"
                className="g-recaptcha"
                data-sitekey="6Ld1rfAUAAAAAHgaz5vNleru6VvxXFRwqcWmem5t"
              ></div>
              <br />

              <input
                type="submit"
                name="submit"
                value="Submit Request"
                className="primaryAction btn blue"
              />
            </form>

            <div className="Contact-actions">
              <a href="https://finswash.com/#Location">
                <Button text="View Fins Locations" />
              </a>
            </div>
          </div>
        </div>
        <div className="Contact-media">
          <Img
            fluid={bubblesImg}
            imgStyle={{ objectFit: 'contain' }}
            className="Contact-media__image"
            style={{ maxWidth: '444px', display: 'none' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
