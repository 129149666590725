import React from 'react';
import classnames from 'classnames';
import './button.css';

export interface ButtonProps {
  style?: React.CSSProperties;
  text?: string;
  className?: string;
  onClick?: VoidFunction;
  submit?: boolean;
  href?: string;
}

const Button: React.FC<ButtonProps> = ({
  style,
  text,
  className,
  onClick,
  submit = false,
  href,
  children
}) => {
  const classNames = classnames({
    Button: true,
    [className || '']: typeof className !== 'undefined'
  });

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const props: any = {
    className: classNames,
    style
  };

  if (!href) {
    props.role = 'button';
    props.onClick = handleClick;
    if (submit) {
      props.type = 'submit';
    }
  } else {
    props.href = href;
  }

  return React.createElement(
    href ? 'a' : submit ? 'button' : 'div',
    props,
    children || text
  );
};

export default Button;
